import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";

import { Investments } from "./types";

export const ordersApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    exitFromInvestment: builder.mutation<any, string>({
      query: (investmentId) => ({
        url: urlPrepare(`/order/investment/${investmentId}/exit`),
        method: "PUT"
      }),
      invalidatesTags: ["Orders"]
    }),
    cancelInvestment: builder.mutation<any, string>({
      query: (investmentId) => ({
        url: urlPrepare(`/order/investment/${investmentId}/cancel`),
        method: "PUT"
      }),
      invalidatesTags: ["Orders"]
    }),
    getInvestmentsSnapshots: builder.query<
      Investments,
      { investmentId: string; inceptionDate: string }
    >({
      query: ({ investmentId, inceptionDate }) =>
        `/meta/investments/snapshots/${investmentId}/${inceptionDate}`,
      providesTags: ["Orders"]
    }),
    getOrders: builder.query<any, string>({
      query: (type) => urlPrepare(process.env.REACT_APP_ORDERS, { type }),
      providesTags: ["Orders"]
    }),
    getOrdersByClientId: builder.query<any, { type: string; status: string; id: string }>({
      query: ({ type, status, id }) =>
        urlPrepare(
          process.env.REACT_APP_CLIENT_ORDERS,
          {
            type,
            id
          },
          { status }
        )
    }),
    getOrderById: builder.query<any, { type: string; id: string }>({
      query: ({ type, id }) => {
        return urlPrepare(process.env.REACT_APP_ORDER_BY_ID, { type, id })
      } ,
      providesTags: ["Orders"]
    }),
    getLeanOrderById: builder.query<any, string>({
      query: (id) => urlPrepare(`/payment/transaction?provider=leantech&intentId=${id}`),
      providesTags: ["Orders"]
    }),
    getWithdrawals: builder.query<any, string>({
      query: (type) => urlPrepare(`/withdrawals/orders?status=${type}`),
      providesTags: ["Orders"]
    }),
    getAllOrdersDeposits: builder.query<any, string | null>({
      query: (type) =>
        urlPrepare(type ? `/orders/deposits/all?status=${type}` : `/orders/deposits/all`),
      providesTags: ["Orders"]
    }),
    approveOrder: builder.mutation<any, { id: string; approvalData: any; type: string }>({
      query: ({ id, approvalData, type }) => {
        const formData = new FormData();
        Object.keys(approvalData).forEach((elem) => {
          formData.append(elem, approvalData[elem]);
        });
        return {
          url: urlPrepare(process.env.REACT_APP_EXECUTE_ORDER, { type, id }),
          method: "POST",
          body: formData
        };
      },
      invalidatesTags: ["Orders"]
    }),
    approveLeanOrder: builder.mutation<any, { id: string; approvalData: any; intentId: string }>({
      query: ({ id, approvalData, intentId }) => {
        const formData = new FormData();
        Object.keys(approvalData).forEach((elem) => {
          formData.append(`${elem}`, approvalData[elem]);
        });
        formData.append("intentId", intentId);
        return {
          url: urlPrepare(process.env.REACT_APP_EXECUTE_LEANORDER, { id }),
          method: "POST",
          body: formData
        };
      },
      invalidatesTags: ["Orders"]
    }),
    createDeposit: builder.mutation<any, { id: string; formData: any }>({
      query: ({ id, formData }) => ({
        url: urlPrepare(process.env.REACT_APP_CREATE_DEPOSIT, { id }),
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["Orders"]
    }),
    cancelDeposit: builder.mutation<any, { id: string; provider: string }>({
      query: ({ id, provider = "manual" }) => ({
        url: urlPrepare(process.env.REACT_APP_CANCEL_DEPOSIT, { id }),
        method: "POST",
        body: {
          provider
        }
      }),
      invalidatesTags: ["Orders"]
    }),
    createWithdraw: builder.mutation<any, { id: string; formData: any }>({
      query: ({ id, formData }) => ({
        url: urlPrepare(process.env.REACT_APP_CREATE_WITHDRAW, { id }),
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["Orders"]
    }),
    cancelWithdraw: builder.mutation<any, string>({
      query: (id) => ({
        url: urlPrepare(process.env.REACT_APP_CANCEL_WITHDRAW, { id }),
        method: "POST"
      }),
      invalidatesTags: ["Orders"]
    })
  })
});

export const {
  useExitFromInvestmentMutation,
  useCancelInvestmentMutation,
  useGetInvestmentsSnapshotsQuery,
  useGetOrdersQuery,
  useGetOrdersByClientIdQuery,
  useGetOrderByIdQuery,
  useGetLeanOrderByIdQuery,
  useGetWithdrawalsQuery,
  useGetAllOrdersDepositsQuery,
  useApproveOrderMutation,
  useApproveLeanOrderMutation,
  useCreateDepositMutation,
  useCancelDepositMutation,
  useCreateWithdrawMutation,
  useCancelWithdrawMutation
} = ordersApi;
