import { SplitApi } from "redux/api";

export const imagesApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getImages: builder.query<any, string>({
      query: (filename) => `${process.env.REACT_APP_IMAGES}/${filename}`
    })
  })
});

export const { useGetImagesQuery } = imagesApi;
