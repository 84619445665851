import { SplitApi } from "redux/api";

export const groupsApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchGroupById: builder.query<any, void>({
      query: () => `pam/managers/current`
    })
  })
});

export const { useFetchGroupByIdQuery } = groupsApi;
