import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAuthenticatedUser } from "redux/auth/AuthSlice";
import axiosInstance from "../helpers/axiosInstance";
import authService from "../services/auth";

export const useAuthListener = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const getMe = async () => {
      try {
        if (authService.isAuthenticated()) {
          setIsLoading(true);
          const res = await axiosInstance.get(process.env.REACT_APP_USER_ME);
          dispatch(getAuthenticatedUser(res.data));
          setIsAuthenticated(true);
          setIsLoading(false);
        } else throw new Error("Token not found");
      } catch (error) {
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    };

    getMe();
  }, [dispatch]);

  return { isLoading, isAuthenticated, setIsAuthenticated };
};
