import { SplitApi } from "redux/api";

export const connectionLogApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getConnections: builder.query({
      query: (id) => process.env.REACT_APP_CONNECTIVITY + id
    }),
    getConnectionById: builder.query({
      query: ({ userId, id }) => `${process.env.REACT_APP_CONNECTIVITY}${userId}/${id}`
    })
  })
});

export const { useGetConnectionsQuery, useGetConnectionByIdQuery } = connectionLogApi;
