import { SplitApi } from "redux/api";

export const authApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query({
      query: () => `/users/me`
    }),
    userLogin: builder.mutation({
      query: (data) => ({
        url: (process.env.REACT_APP_API_HOST as string) + process.env.REACT_APP_USER_LOGIN,
        method: "POST",
        body: {
          email: data.email,
          password: data.encryptedPassword
        }
      }),
      transformResponse: (response: any) => {
        localStorage.setItem("token", response.token);
        return response;
      }
    })
  })
});
export const { useGetMeQuery, useUserLoginMutation } = authApi;
