export const DARK_MODE = "realDark";
export const LIGHT_MODE = "light";

const MODE_COLOR = "_MODE_COLOR";

export const setModeColor = (mode) => {
    localStorage.setItem(MODE_COLOR, mode);
}

export const getModeColor = () => {
    return localStorage.getItem(MODE_COLOR);
}

export const removeModeColor = () => {
    localStorage.removeItem(MODE_COLOR);
}