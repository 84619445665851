import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";
import { RiskProfile } from "interfaces/ClientObjects";

export const riskProfileSettingServiceApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllRiskProfile: builder.query<RiskProfile[], void>({
      query: () => urlPrepare(process.env.REACT_APP_GET_ALL_RISK_PROFILE),
      providesTags: ["RiskProfileSettingService"]
    }),
    getRiskProfileById: builder.query<RiskProfile, string>({
      query: (riskProfilId) =>
        urlPrepare(process.env.REACT_APP_GET_RISK_PROFILE_BY_ID + riskProfilId),
      providesTags: ["RiskProfileSettingService"]
    }),
    addNewRiskProfile: builder.mutation<RiskProfile, RiskProfile>({
      query: (newRiskProfile) => ({
        url: urlPrepare(process.env.REACT_APP_ADD_RISK_PROFILE),
        method: "POST",
        body: newRiskProfile
      }),
      invalidatesTags: ["RiskProfileSettingService"]
    })
  })
});

export const {
  useGetAllRiskProfileQuery,
  useGetRiskProfileByIdQuery,
  useAddNewRiskProfileMutation
} = riskProfileSettingServiceApi;
