import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";

export const feesApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFees: builder.query({
      query: () => urlPrepare(process.env.REACT_APP_GET_FEES)
    }),
    getClientFees: builder.query({
      query: (id) => urlPrepare(process.env.REACT_APP_GET_CLIENT_FEES, { id })
    })
  }),
  overrideExisting: true
});

export const { useGetFeesQuery, useGetClientFeesQuery } = feesApi;
