import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";
import { Group } from "./types";

export const pamGroupApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllGroups: builder.query<Group[], void>({
      query: () => urlPrepare(process.env.REACT_APP_PAM_GROUPS),
      providesTags: ["PamGroup"]
    }),
    getGroupById: builder.query<Group, string>({
      query: (id) => urlPrepare(process.env.REACT_APP_PAM_GROUPS + id),
      providesTags: ["PamGroup"]
    }),
    getGroupByManagerId: builder.query<Group[], string>({
      query: (id) => urlPrepare("/pam/manager/" + id),
      providesTags: ["PamGroup"]
    }),
    addNewGroup: builder.mutation<Group, Group>({
      query: (data) => ({
        url: urlPrepare(process.env.REACT_APP_PAM_GROUPS),
        method: "POST",
        body: data
      }),
      invalidatesTags: ["PamGroup"]
    }),
    updateGroup: builder.mutation<Group, { id: string; newData: Group }>({
      query: ({ id, newData }) => ({
        url: urlPrepare(process.env.REACT_APP_PAM_GROUPS + id),
        method: "PUT",
        body: newData
      }),
      invalidatesTags: ["PamGroup"]
    }),
    deleteGroup: builder.mutation<Group, string>({
      query: (id) => ({
        url: urlPrepare(process.env.REACT_APP_PAM_GROUPS + id),
        method: "DELETE"
      }),
      invalidatesTags: ["PamGroup"]
    })
  })
});

export const {
  useGetAllGroupsQuery,
  useGetGroupByIdQuery,
  useGetGroupByManagerIdQuery,
  useAddNewGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation
} = pamGroupApi;
