import { createSlice } from "@reduxjs/toolkit";

export interface FormType {
  amount: number | null;
  attachments: any[];
  comment: string;
  date: string;
  security: string;
}
export interface IFormType {
  FormType: FormType;
}

const initialState: IFormType = {
  FormType: {
    amount: null,
    attachments: [],
    comment: "",
    date: "",
    security: ""
  }
};

export const dividendFormSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    formValues: (state, action: { type: string; payload: any }) => {
      state.FormType = action.payload;
    }
  }
});

export const { formValues } = dividendFormSlice.actions;
