import { urlPrepare } from "helpers";
import { Role, User, UserByRole, newUser, updatedUser, Crm } from "./types";
import { SplitApi } from "redux/api";
import Swal from "components/Swal";

export const usersApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => urlPrepare(process.env.REACT_APP_USER_ME),
      providesTags: ["Users"]
    }),
    getUserById: builder.query<User, string>({
      query: (id) => urlPrepare(process.env.REACT_APP_USER_INFO, { id }),
      providesTags: ["Users"]
    }),
    getUsersByRole: builder.query<UserByRole[], Role | string>({
      query: (role) => urlPrepare(process.env.REACT_APP_USERS_ROLE, { role }),
      providesTags: ["Users"]
    }),
    getUsers: builder.query<User[], void>({
      query: () => urlPrepare(process.env.REACT_APP_USERS),
      providesTags: ["Users"]
    }),
    addUser: builder.mutation<User, newUser>({
      query: (newUser) => ({
        url: urlPrepare(process.env.REACT_APP_USERS),
        method: "POST",
        body: newUser
      }),
      transformResponse: (response: User) => {
        Swal.fire({
          icon: "success",
          title: "User has been saved",
          showConfirmButton: false,
          timer: 2500
        });
        return response;
      },
      transformErrorResponse: (response: any) => {
        Swal.fire({
          icon: "error",
          title: response.data.message ?? "Cannot add user!",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2500
        });
      },
      invalidatesTags: ["Users"]
    }),
    updateUser: builder.mutation<updatedUser, { id: string; formData: updatedUser }>({
      query: ({ id, formData }) => ({
        url: urlPrepare(process.env.REACT_APP_USER_INFO, { id }),
        method: "PUT",
        body: formData
      }),
      invalidatesTags: ["Users"]
    }),
    resetPassword: builder.mutation<void, { id: string; oldPassword: string; newPassword: string }>(
      {
        query: ({ id, oldPassword, newPassword }) => ({
          url: urlPrepare(process.env.REACT_APP_USER_RESET_PASSWORD, { id }),
          method: "PUT",
          body: { oldPassword, newPassword }
        }),
        invalidatesTags: ["Users"]
      }
    ),
    activateUser: builder.mutation<{ success: boolean }, string>({
      query: (id) => ({
        url: urlPrepare(process.env.REACT_APP_ACTIVATE_USER, { id }),
        method: "POST"
      }),
      invalidatesTags: ["Users"]
    }),
    desactivateUser: builder.mutation<{ success: boolean }, string>({
      query: (id) => ({
        url: urlPrepare(process.env.REACT_APP_DESACTIVATE_USER, { id }),
        method: "POST"
      }),
      invalidatesTags: ["Users"]
    }),
    deleteUser: builder.mutation<{ success: boolean }, string>({
      query: (id) => ({
        url: urlPrepare(process.env.REACT_APP_DELETE_USER, { id })
      }),
      invalidatesTags: ["Users"]
    }),
    recoverUser: builder.mutation<{ success: boolean }, string>({
      query: (id) => ({
        url: urlPrepare(process.env.REACT_APP_RECOVER_USER, { id }),
        method: "POST"
      }),
      invalidatesTags: ["Users"]
    }),
    assignCR: builder.mutation<any, { id: string; assignedCRM: string }>({
      query: ({ id, assignedCRM }) => ({
        url: urlPrepare(process.env.REACT_APP_USER_ASSIGN_CRM, { id }),
        method: "POST",
        body: assignedCRM
      }),
      invalidatesTags: ["Users"]
    }),
    removeCR: builder.mutation<any, { id: string; crmToRemove: string }>({
      query: ({ id, crmToRemove }) => ({
        url: urlPrepare(process.env.REACT_APP_USER_REMOVE_CRM, { id }),
        method: "POST",
        body: crmToRemove
      }),
      invalidatesTags: ["Users"]
    }),
    getCRMByClientId: builder.query<Crm, string>({
      query: (id) => urlPrepare(process.env.REACT_APP_CRM_INFO, { id }),
      providesTags: ["Users"]
    }),
    assignClientsToManager: builder.mutation<any, { managerId: string; clientsId: string[] }>({
      query: (values) => ({
        url: urlPrepare(process.env.REACT_APP_ASSIGN_CLIENTS_TO_MANAGER),
        method: "POST",
        body: values,
        responseHandler: (response) => response.text()
      }),
      invalidatesTags: ["Users"]
    }),
    pamNewInvestment: builder.mutation<any, Object>({
      query: (investment) => ({
        url: "/pam/create-investment",
        method: "POST",
        body: investment
      }),
      invalidatesTags: ["Users"]
    })
  })
});
export const {
  useGetUserQuery,
  useGetUserByIdQuery,
  useGetUsersByRoleQuery,
  useGetUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useResetPasswordMutation,
  useActivateUserMutation,
  useDesactivateUserMutation,
  useDeleteUserMutation,
  useRecoverUserMutation,
  useAssignCRMutation,
  useRemoveCRMutation,
  useGetCRMByClientIdQuery,
  useAssignClientsToManagerMutation,
  usePamNewInvestmentMutation
} = usersApi;
