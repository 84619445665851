import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";
import {  Model } from "./types";

export const parameterSettingServiceApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllFrequencies: builder.query<any, void>({
      query: () => urlPrepare(process.env.REACT_APP_GET_ALL_FREQUENCIES)
    }),
    getAllBenchmarks: builder.query<any[], void>({
      query: () => urlPrepare(process.env.REACT_APP_GET_ALL_BENCHMARKS)
    }),
    getAllModels: builder.query<Model[], void>({
      query: () => urlPrepare(process.env.REACT_APP_GET_ALL_MODELS)
    })
  })
});

export const { useGetAllFrequenciesQuery, useGetAllBenchmarksQuery, useGetAllModelsQuery } =
  parameterSettingServiceApi;
