import { urlPrepare } from "helpers";
import { ZagOrder } from "./types";
import { SplitApi } from "redux/api";

export const zagOrdersApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvestmentZagOrders: builder.query<ZagOrder[], string>({
      query: (investmentId) =>
        urlPrepare(process.env.REACT_APP_GET_INVESTMENT_ZAG_ORDERS, {
          investmentId
        })
    }),
    getAllZagOrders: builder.query<ZagOrder[], string | void>({
      query: (targetdate) => {
        let date;
        if (!targetdate || targetdate.length === 0) date = new Date().toLocaleDateString();
        else date = targetdate;
        return urlPrepare(process.env.REACT_APP_GET_ALL_ZAG_ORDERS, null, { date });
      }
    }),
    getInvestmentFailedZagOrders: builder.query<ZagOrder[], string>({
      query: (investmentId) =>
        urlPrepare(process.env.REACT_APP_GET_INVESTMENT_ZAG_FAILED_ORDERS, {
          investmentId
        })
    })
  })
});
export const {
  useGetInvestmentZagOrdersQuery,
  useGetAllZagOrdersQuery,
  useGetInvestmentFailedZagOrdersQuery
} = zagOrdersApi;
