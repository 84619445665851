import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";
import { Solution } from "./types";

export const solutionSettingServiceApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getSolutions: builder.query<Solution[], void>({
      query: () => urlPrepare(process.env.REACT_APP_GET_SOLUTIONS),
      providesTags: ["SolutionSettingService"]
    }),
    getSolutionById: builder.query<Solution, string>({
      query: (solution_id) =>
        urlPrepare(process.env.REACT_APP_GET_SOLUTION_BY_ID + { solution_id }.solution_id),
      providesTags: ["SolutionSettingService"]
    }),
    newSolution: builder.mutation<any, Solution>({
      query: (newSolution) => ({
        url: urlPrepare(process.env.REACT_APP_ADD_NEW_SOLUTION),
        method: "POST",
        body: newSolution
      }),
      invalidatesTags: ["SolutionSettingService"]
    }),
    editSolution: builder.mutation<Solution, { editedSolution: Solution; id: string }>({
      query: ({ editedSolution, id }) => ({
        url: urlPrepare(process.env.REACT_APP_ADD_NEW_SOLUTION + id),
        method: "PUT",
        body: editedSolution
      }),
      invalidatesTags: ["SolutionSettingService"]
    }),
    deleteSolution: builder.mutation<Solution, string>({
      query: (id) => ({
        url: urlPrepare(process.env.REACT_APP_DELETE_SOLUTION_BY_ID + id),
        method: "DELETE"
      }),
      invalidatesTags: ["SolutionSettingService"]
    }),
    deleteSolutions: builder.mutation<Solution[], Solution[]>({
      query: (items) => ({
        url: urlPrepare(`/settings/solutions?items=${items}`),
        method: "DELETE"
      }),
      invalidatesTags: ["SolutionSettingService"]
    })
  })
});

export const {
  useGetSolutionsQuery,
  useGetSolutionByIdQuery,
  useNewSolutionMutation,
  useEditSolutionMutation,
  useDeleteSolutionMutation,
  useDeleteSolutionsMutation
} = solutionSettingServiceApi;
