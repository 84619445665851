import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";
import { BackTest } from "./types";

export const backtestApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBackTests: builder.query<BackTest[], void>({
      query: () => urlPrepare(`${process.env.REACT_APP_BACKTEST}all`),
      providesTags: ["BackTest"]
    }),

    getBackTestById: builder.query<BackTest, string>({
      query: (id) => `/solver/backtests/${id}`,
      providesTags: ["BackTest"]
    }),
    createBackTest: builder.mutation<any, any>({
      query: (backTest) => ({
        url: process.env.REACT_APP_BACKTEST as string,
        method: "POST",
        body: backTest
      }),
      invalidatesTags: ["BackTest"]
    }),
    deleteBackTest: builder.mutation<BackTest, string>({
      query: (id) => ({
        url: (process.env.REACT_APP_BACKTEST as string) + id,
        method: "DELETE"
      }),
      invalidatesTags: ["BackTest"]
    })
  })
});
export const {
  useCreateBackTestMutation,
  useGetAllBackTestsQuery,
  useGetBackTestByIdQuery,
  useDeleteBackTestMutation
} = backtestApi;
