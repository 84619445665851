import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";
import { BankAccount } from "./types";

export const bankAccountsApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getClientBankAccounts: builder.query<BankAccount[], string>({
      query: (id) => urlPrepare(process.env.REACT_APP_BANK_ACCOUNT, { id }),
      providesTags: ["BankAccounts"]
    }),
    createBankAccount: builder.mutation<BankAccount, { id: string; formData: BankAccount }>({
      query: ({ id, formData }) => ({
        url: urlPrepare(process.env.REACT_APP_BANK_ACCOUNT, { id }),
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["BankAccounts"]
    })
  })
});
export const { useCreateBankAccountMutation, useGetClientBankAccountsQuery } = bankAccountsApi;
