import { createSlice } from "@reduxjs/toolkit";

type Step = "dividend" | "rebalancing";

interface initialState {
  step: Step;
}

const initialState: initialState = {
  step: "dividend"
};

export const dividendStepSlice = createSlice({
  name: "dividendStep",
  initialState,
  reducers: {
    dividendStep: (state, action) => {
      state.step = action.payload;
    }
  }
});

export const { dividendStep } = dividendStepSlice.actions;
