import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.GATEWAY_SERVICE_HOST || process.env.REACT_APP_API_HOST,
  timeout: 20000000,
  headers: {
    Accept: "application/json",
  }
});

let isRefreshing = false;

const toggleRefreshing = () => (isRefreshing = !isRefreshing);

let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter((callback) => callback(access_token));
}

const logout = () => {
  localStorage.removeItem("token");
  subscribers = [];
};

function refreshToken() {
  if (!localStorage.getItem("token")) {
    // logout()
    throw new Error("No Access Token");
  }

  return axios
    .get(process.env.REACT_APP_API_HOST + "/auth/token/refresh", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
    .then((res) => {
      // set localStorage token
      localStorage.setItem("token", res.data.token);
      return res.data.token;
    })
    .catch(() => {
      logout();
      throw new Error("Couldn't refresh token");
    });
}

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers.token) {
      const token = localStorage.getItem("token");

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      if (!isRefreshing) {
        toggleRefreshing();
        refreshToken()
          .then((access_token) => {
            toggleRefreshing();
            onAccessTokenFetched(access_token);
          })
          .catch((err) => {
            console.log("isRefreshing err", err.message);
          });
      }
    }
    return Promise.reject(error);
  }
);

// export const axiosReduxErrorHandler = (error, dispatch) => {
//   if (error.response) {
//     if (error.response.status === 400) throw error.response;

//     const msg = error.response.data.message || error.message;
//     dispatch({
//       type: TYPES.ADD_TOAST,
//       payload: {
//         type: "warning",
//         message: `${error.response.status}: ${msg}`
//       }
//     });
//   } else {
//     dispatch({
//       type: TYPES.ADD_TOAST,
//       payload: {
//         type: "error",
//         message: error.message
//       }
//     });
//   }
// };

export default axiosInstance;
