import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";

export const tickersAssetServiceApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getTickersAssetService: builder.query<any, void>({
      query: () => process.env.REACT_APP_ROBO_ASSETSERVICE_TICKERS as string,
      providesTags: ["TickersAssetService"]
    }),
    getLightTickers: builder.query<any, void>({
      query: () => urlPrepare(`${process.env.REACT_APP_API_HOST}/tickers/light`),
      providesTags: ["TickersAssetService"]
    }),
    getTickerBySymbol: builder.query<any, string>({
      query: (symbol) => urlPrepare(`/tickers/${symbol}`),
      providesTags: ["TickersAssetService"]
    }),
    addTicker: builder.mutation<any, any>({
      query: (values) => ({
        url: urlPrepare(process.env.REACT_APP_TICKERS),
        method: "POST",
        body: values
      }),
      invalidatesTags: ["TickersAssetService"]
    }),
    editTicker: builder.mutation<any, any>({
      query: (editedTicker) => ({
        url: urlPrepare(
          `${process.env.REACT_APP_API_HOST} + ${process.env.REACT_APP_ROBO_ASSETSERVICE_TICKER}`
        ),
        method: "PUT",
        body: editedTicker
      }),
      invalidatesTags: ["TickersAssetService"]
    }),
    deleteTicker: builder.mutation<any, string>({
      query: (symbol) => ({
        url: urlPrepare(`/tickers?symbol=${symbol}`),
        method: "DELETE"
      }),
      invalidatesTags: ["TickersAssetService"]
    }),
    updateTicker: builder.mutation<any, { symbol: string; values: any }>({
      query: ({ symbol, values }) => ({
        url: urlPrepare(`/tickers?symbol=${symbol}`),
        method: "DELETE",
        body: values
      }),
      invalidatesTags: ["TickersAssetService"]
    })
  })
});

export const {
  useGetTickersAssetServiceQuery,
  useGetLightTickersQuery,
  useGetTickerBySymbolQuery,
  useAddTickerMutation,
  useEditTickerMutation,
  useDeleteTickerMutation,
  useUpdateTickerMutation
} = tickersAssetServiceApi;
