import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";
import { Value } from "./types";

export const rebalancingRequestsApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRebalancingRequests: builder.query<Value[], any>({
      query: (queryParams) => {
        const queryString = new URLSearchParams(queryParams).toString();
        return process.env.REACT_APP_REBALANCING + "?" + queryString;
      },
      providesTags: ["RebalancingRequests"]
    }),
    getRebalancingRequestById: builder.query<Value, string>({
      query: (id) => urlPrepare(process.env.REACT_APP_GET_REBALANCING_REQUEST, { id }),
      providesTags: ["RebalancingRequests"]
    }),
    createRebalancingRequest: builder.mutation<any, Value>({
      query: (data) => ({
        url: urlPrepare(process.env.REACT_APP_REBALANCING),
        method: "POST",
        body: data
      }),
      invalidatesTags: ["RebalancingRequests"]
    }),
    simulateRebalancing: builder.mutation<any, string>({
      query: (id) => ({
        url: urlPrepare(process.env.REACT_APP_SIMULATE_REBALANCING, {
          id
        }),
        method: "POST"
      }),
      invalidatesTags: ["RebalancingRequests"]
    }),
    executeRebalancingRequest: builder.mutation<any, { id: string; investmentsIds: string[] }>({
      query: ({ id, investmentsIds }) => ({
        url: urlPrepare(
          process.env.REACT_APP_EXECUTE_REBALANCING_REQUEST,
          {
            id
          },
          { investmentsIds }
        ),
        method: "POST"
      }),
      invalidatesTags: ["RebalancingRequests"]
    }),
    cancelRebalancingRequest: builder.mutation<any, string>({
      query: (id) => ({
        url: urlPrepare(process.env.REACT_APP_CANCEL_REBALANCING_REQUEST, {
          id
        }),
        method: "POST"
      }),
      invalidatesTags: ["RebalancingRequests"]
    })
  })
});

export const {
  useGetRebalancingRequestsQuery,
  useGetRebalancingRequestByIdQuery,
  useCreateRebalancingRequestMutation,
  useSimulateRebalancingMutation,
  useExecuteRebalancingRequestMutation,
  useCancelRebalancingRequestMutation
} = rebalancingRequestsApi;
