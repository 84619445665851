import { SplitApi } from "redux/api";

export const newsSentimentsApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getNews: builder.query<any, { numberOfArticles: number; lookBack: any }>({
      query: ({ numberOfArticles, lookBack }) => ({
        url: `${process.env.REACT_APP_GET_NEWS_ARTICLES}?number_of_articles=${numberOfArticles}&lookback=${lookBack}`
      })
    })
  })
});

export const { useGetNewsQuery } = newsSentimentsApi;
