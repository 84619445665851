import React from "react";
import {  useDispatch, useSelector } from "react-redux";
import Toast from '../components/Toast'
import { removeToast } from "redux/toaster/toasterSlice";


const Toasts = () => {
  const {toasts} = useSelector((state) => state.toaster)
  const dispatch = useDispatch();
  return(
      <div id="toast-container" className="toast-top-right">
        {
          toasts.map(toast => <Toast
            key={toast.id}
            message={toast.message}
            type={toast.type}
            onClose={() => dispatch(removeToast(toast.id))}/>
          )
        }
      </div>
    )
}
export default Toasts
