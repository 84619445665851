import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";

export const mailsApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    emailForm: builder.mutation<any, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: urlPrepare(process.env.REACT_APP_EMAIL_FORM, { id }),
        method: "POST",
        body
      }),
      invalidatesTags: ["Mails"]
    }),
    getClientSentMails: builder.query<any, string>({
      query: (id) => urlPrepare(process.env.REACT_APP_CLIENT_MAIL, { id }),
      providesTags: ["Mails"]
    })
  })
});

export const { useEmailFormMutation, useGetClientSentMailsQuery } = mailsApi;
