import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import Toasts from "./containers/Toasts";
import { IsUserRedirect, ProtectedRoute } from "./helpers/routes";
import { useAuthListener } from "./hooks/auth";

import "react-toastify/dist/ReactToastify.css";

const Dashboard = React.lazy(() => import("./containers/Dashboard"));
const Login = React.lazy(() => import("./containers/Login/Login"));

function App() {
  const { isLoading, isAuthenticated, setIsAuthenticated } = useAuthListener();
  const user = useSelector((state) => state.auth.authenticatedUser);
  // const { data: test, isLoading, error, isSuccess: isAuthenticated } = useGetMeQuery();
  // useEffect(() => {
  //   if (error) showNotification({ message: "Token not found!", type: "error" });
  // }, [error]);

  useEffect(() => {
    if (!user) {
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);
    }
  }, [user, setIsAuthenticated]);
  return (
    <React.Suspense fallback={<div></div>}>
      <ToastContainer />
      <Switch>
        <IsUserRedirect
          isAuthenticated={isAuthenticated}
          isLoading={isLoading}
          path="/login"
          loggedInPath="/"
          component={Login}
        />
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          isLoading={isLoading}
          path="/"
          component={Dashboard}
        />
      </Switch>
      <Toasts />
    </React.Suspense>
  );
}

// const mapStateToProps = () => (state) => ({
//   user: state.auth.authenticatedUser
// });

export default App;
// export default connect(mapStateToProps, null)(App);
