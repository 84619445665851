import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";

export const brokerFeesApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getBrokerFees: builder.query<BrokerFees, void>({
      query: () => urlPrepare(process.env.REACT_APP_GET_FEES)
    })
  })
});
export const { useGetBrokerFeesQuery } = brokerFeesApi;
