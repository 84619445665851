import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";

export const pamCommentsApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getComments: builder.query<any, string>({
      query: (id) => urlPrepare(process.env.REACT_APP_API_HOST + `/comments/${id}`),
      providesTags: ["PamComments"]
    }),
    addPamComment: builder.mutation<any, any>({
      query: (values) => ({
        url: urlPrepare(process.env.REACT_APP_API_HOST + `newComment/`),
        method: "POST",
        body: values
      }),
      invalidatesTags: ["PamComments"]
    }),
    deletePamComment: builder.mutation<any, any>({
      query: (values) => ({
        url: urlPrepare(process.env.REACT_APP_API_HOST + `deleteComment/`),
        method: "POST",
        body: values
      }),
      invalidatesTags: ["PamComments"]
    })
  })
});

export const { useGetCommentsQuery, useAddPamCommentMutation, useDeletePamCommentMutation } =
  pamCommentsApi;
