import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { zagOrdersApi } from "redux/zagOrders/api";
import { authApi } from "redux/auth/api";
import { userRolesApi } from "redux/userRoles/api";
import { usersApi } from "redux/users/api";
import { tickersApi } from "redux/tickers/api";
import { clientsApi } from "redux/clients/api";
import { accountStatementsApi } from "redux/accountStatements/api";
import { backtestApi } from "redux/backtest/api";
import { bankAccountsApi } from "redux/bankAccounts/api";
import { basketSettingServiceApi } from "redux/basketSettingService/api";
import { brokerFeesApi } from "redux/brokerFees/api";
import { CommissionsApi } from "redux/commissions/api";
import { connectionLogApi } from "redux/connectionLog/api";
import { feesApi } from "redux/fees/api";
import { groupsApi } from "redux/groups/api";
import { imagesApi } from "redux/images/api";
import { mailsApi } from "redux/mails/api";
import { newsSentimentsApi } from "redux/newsSentiments/api";
import { ordersApi } from "redux/orders/api";
import { ordersSummaryApi } from "redux/ordersSummary/api";
import { pamCommentsApi } from "redux/pamComments/api";
import { pamGroupApi } from "redux/pamGroup/api";
import { parameterSettingServiceApi } from "redux/parameterSettingService/api";
import { rebalancingRequestsApi } from "redux/rebalancingRequests/api";
import { riskProfileSettingServiceApi } from "redux/riskProfileSettingService/api";
import { sentimentsApi } from "redux/sentiments/api";
import { solutionSettingServiceApi } from "redux/solutionSettingService/api";
import { strategySettingServiceApi } from "redux/strategieSettingService/api";
import { templatesApi } from "redux/templates/api";
import { tickersAssetServiceApi } from "redux/tickersAssetService/api";
import { authSlice } from "redux/auth/AuthSlice";
import { newsSlice } from "redux/newsSentiments/newsSlice";
import { StrategieSlice } from "redux/strategieSettingService/strategieSlice";
import { toasterSlice } from "redux/toaster/toasterSlice";
import { dividendApi } from "redux/dividend/api";
import { dividendFormSlice } from "redux/dividend/dividendFormSlice";
import { dividendStepSlice } from "redux/dividend/dividendStepSlice";

const allReducers = combineReducers({
  toaster: toasterSlice.reducer,
  auth: authSlice.reducer,
  news: newsSlice.reducer,
  strategie: StrategieSlice.reducer,
  dividend: dividendFormSlice.reducer,
  dividendStep: dividendStepSlice.reducer,
  [zagOrdersApi.reducerPath]: zagOrdersApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [userRolesApi.reducerPath]: userRolesApi.reducer,
  [clientsApi.reducerPath]: clientsApi.reducer,
  [accountStatementsApi.reducerPath]: accountStatementsApi.reducer,
  [backtestApi.reducerPath]: backtestApi.reducer,
  [bankAccountsApi.reducerPath]: bankAccountsApi.reducer,
  [basketSettingServiceApi.reducerPath]: basketSettingServiceApi.reducer,
  [brokerFeesApi.reducerPath]: brokerFeesApi.reducer,
  [CommissionsApi.reducerPath]: CommissionsApi.reducer,
  [connectionLogApi.reducerPath]: connectionLogApi.reducer,
  [feesApi.reducerPath]: feesApi.reducer,
  [groupsApi.reducerPath]: groupsApi.reducer,
  [imagesApi.reducerPath]: imagesApi.reducer,
  [mailsApi.reducerPath]: mailsApi.reducer,
  [newsSentimentsApi.reducerPath]: newsSentimentsApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [ordersSummaryApi.reducerPath]: ordersSummaryApi.reducer,
  [pamCommentsApi.reducerPath]: pamCommentsApi.reducer,
  [pamGroupApi.reducerPath]: pamGroupApi.reducer,
  [parameterSettingServiceApi.reducerPath]: parameterSettingServiceApi.reducer,
  [rebalancingRequestsApi.reducerPath]: rebalancingRequestsApi.reducer,
  [riskProfileSettingServiceApi.reducerPath]: riskProfileSettingServiceApi.reducer,
  [sentimentsApi.reducerPath]: sentimentsApi.reducer,
  [solutionSettingServiceApi.reducerPath]: solutionSettingServiceApi.reducer,
  [strategySettingServiceApi.reducerPath]: strategySettingServiceApi.reducer,
  [templatesApi.reducerPath]: templatesApi.reducer,
  [tickersApi.reducerPath]: tickersApi.reducer,
  [tickersAssetServiceApi.reducerPath]: tickersAssetServiceApi.reducer,
  [dividendApi.reducerPath]: dividendApi.reducer
});

export const store = configureStore({
  reducer: allReducers,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    });
    return [
      ...defaultMiddleware,
      zagOrdersApi.middleware,
      authApi.middleware,
      userRolesApi.middleware,
      accountStatementsApi.middleware,
      backtestApi.middleware
    ] as ReturnType<typeof getDefaultMiddleware>;
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
