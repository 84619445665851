import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";

export const CommissionsApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addCommission: builder.mutation({
      query: (commission) => ({
        url: process.env.REACT_APP_COMMISSION_ROUTE as string,
        method: "POST",
        body: {
          type: commission.style,
          percentage: commission.commissionPercent
        }
      }),
      invalidatesTags: ["Commissions"]
    }),
    getCommissions: builder.query({
      query: () => urlPrepare(process.env.REACT_APP_COMMISSION_ROUTE),
      providesTags: ["Commissions"]
    }),
    updateCommission: builder.mutation({
      query: ({ style, percentage }) => ({
        url: `${process.env.REACT_APP_COMMISSION_ROUTE as string}/${style}`,
        method: "POST",
        body: percentage
      }),
      invalidatesTags: ["Commissions"]
    }),
    getHistory: builder.query({
      query: (style) => `${process.env.REACT_APP_COMMISSION_ROUTE}/history/${style}`,
      providesTags: ["Commissions"]
    })
  })
});
export const {
  useAddCommissionMutation,
  useUpdateCommissionMutation,
  useGetCommissionsQuery,
  useGetHistoryQuery
} = CommissionsApi;
