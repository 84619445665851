import { urlPrepare } from "helpers";
import axiosInstance from "helpers/axiosInstance";
import { SplitApi } from "redux/api";
import {
  handleExitSummary,
  handleOrderSummary,
  handleRebalancingSummary
} from "utils/activitiesSummary";

export const ordersSummaryApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrdersSummary: builder.query<any, any>({
      query: (date) => {
        return urlPrepare(process.env.REACT_APP_GET_ORDERS_SUMMARY + `?date=${date}`);
      },
      transformResponse: async (response: any) => {
        const responseDataKeys = Object.keys(response);
        const ordersSummary: any[] = responseDataKeys.map((key) => {
        let orderType = key
                        .replace("Summary", "")
                        .replace("_", " ")
          if (orderType === "rebalancings")
            return handleRebalancingSummary(response[key], orderType);
          return handleOrderSummary(response[key], orderType);
        });

        const exitsSummary = handleExitSummary(response["public_investmentsSummary"]);

        ordersSummary.push({
          name: "exits",
          ...exitsSummary
        });
        const ordersList = ordersSummary.reduce((acc, curr) => [...acc, ...curr.ordersList], []);
        ordersSummary.forEach((elem) => {
          if (elem.name === "investments") elem.name = "public investments";
        });
        return { ordersSummary, ordersList };
      }
    })
  })
});

export const { useGetOrdersSummaryQuery } = ordersSummaryApi;
