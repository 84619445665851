import { urlPrepare } from "helpers";
import { Role, UserPermission, UserRoles } from "./types";
import { SplitApi } from "redux/api";
import Swal from "components/Swal";

export const userRolesApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserRoles: builder.query<UserRoles[], void>({
      query: () => urlPrepare(process.env.REACT_APP_USER_ROLES),
      providesTags: ["UserRoles"]
    }),
    addRole: builder.mutation<any, string | null>({
      query: (newRole) => ({
        url: urlPrepare(process.env.REACT_APP_ADD_ROLE),
        method: "POST",
        body: newRole
      }),
      transformResponse: (data: any) => Swal.fire({
          icon: 'success',
          title: 'Added!',
          text: data.message,
        }),
        transformErrorResponse: (data: any) => Swal.fire({
            icon: "error",
            title: 'Failure!',
            text: data.message,
            cancelButtonText: "Cancel"
          }),
          
      invalidatesTags: ["UserRoles"]
    }),
    updateRole: builder.mutation<any, any>({
      query: (updatedRole) => ({
        url: urlPrepare(process.env.REACT_APP_UPDATE_ROLE),
        method: "PUT",
        body: updatedRole
      }),
       transformResponse: (data: any) => Swal.fire({
          icon: 'success',
          title: 'Updated!',
          text: data.message,
        }),
        transformErrorResponse: (data: any) => Swal.fire({
            icon: "error",
            title: 'Failure!',
            text: data.message,
            cancelButtonText: "Cancel"
          }),
          invalidatesTags: ["UserRoles"]
    }),
    deleteRole: builder.mutation<any,any>({
      query: (roleId) => ({
        url: process.env.REACT_APP_DELETE_ROLE + roleId,
        method: "DELETE",
      }),
      transformResponse: (res: any) => Swal.fire({
        title: "Deleted!",
        text: "Role deleted successfully!",
        icon: "success"
      }),
      transformErrorResponse: (res: any) => Swal.fire({
        title: "Failure!",
        icon: "error",
        cancelButtonText: "Cancel",
        text: res.data.message ?? "Failed to delete role"
        }),
      invalidatesTags: ["UserRoles"]
    }),
    getPermissions: builder.query<UserPermission[], void>({
      query: () => urlPrepare(process.env.REACT_APP_USER_PERMISSIONS),
      providesTags: ["Permissions"]
    }),
    getRoleDetails: builder.query<Role, string | null>({
      query: (id) => urlPrepare(process.env.REACT_APP_GET_ROLE_DETAILS, { id }),
      providesTags: ["RoleDetails"]
    }),
    updatePermission: builder.mutation<any, { name: string; description: string }>({
      query: (updatedPermission) => ({
        url: urlPrepare(process.env.REACT_APP_UPDATE_PERMISSION),
        method: "PUT",
        body: updatedPermission
      }),
      transformResponse: (data: any) => 
         Swal.fire({
          icon: 'success',
          title: 'Updated!',
          text: data.message,
        }),
        invalidatesTags: ["Permissions", "RoleDetails"]
      
    })
  })
});
export const {
  useGetUserRolesQuery,
  useAddRoleMutation,
  useUpdateRoleMutation,
  useGetPermissionsQuery,
  useGetRoleDetailsQuery,
  useUpdatePermissionMutation,
  useDeleteRoleMutation
} = userRolesApi;
