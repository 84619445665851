export const clientTypes = {
  retail: "Retail",
  professional: "Professional"
  // marketCounterparty: "Market Counterparty"
};

export const userRoles = {
  admin: "ADMIN",
  clientManager: "CRM",
  complianceOfficer: "CO",
  chiefFinancialOfficer: "CFO",
  readOnly: "RO",
  cro: "CRO",
  pam: "PAM"
};

export const ordersStatus = {
  pending: "PENDING",
  executing: "EXECUTING",
  executed: "EXECUTED",
  failed: "FAILED",
  canceled: "CANCELED"
};

export const onboardingSteps = {
  waitingConfirmation: "WAITING_CONFIRMATION",
  premiumEligibility: "PREMIUM_ELIGIBILITY",
  clientRejection: "CLIENT_REJECTION",
  termsAndConditions: "TERMS_AND_CONDITIONS"
};

export const investmentDisplayedStatus = {
  pending: "PENDING",
  executed: "OPENING",
  active: "OPEN",
  failed: "FAILED",
  canceled: "CANCELED",
  pending_exit: "CLOSING",
  active_exit: "EXITING",
  exited: "CLOSED"
};

export const investmentStatusDisplayed = {
  pending: "PENDING",
  executed: "EXECUTED",
  active: "ACTIVE",
  failed: "FAILED",
  canceled: "CANCELED",
  pending_exit: "PENDING_EXIT",
  active_exit: "ACTIVE_EXIT",
  exited: "EXITED"
};

export const investmentStatus = {
  pending: "PENDING",
  executed: "EXECUTED",
  active: "ACTIVE",
  failed: "FAILED",
  canceled: "CANCELED",
  pending_exit: "PENDING_EXIT",
  active_exit: "ACTIVE_EXIT",
  exited: "EXITED"
};

export const rebalancingStatus = {
  pending: "pending",
  executed: "executing",
  resolved: "resolved",
  failed: "failed"
};
export const investmentTypes = {
  standard: "standard",
  premium: "premium",
  vip: "vip",
  composite: "composite"
};

export const riskSolutionTypes = {
  autopilot: "autopilot",
  fullexposure: "fullexposure"
};

export const typesCompatibility = {
  vip: ["all", "vip"],
  premium: ["all", "premium"],
  composite: ["aicomposite"]
};

export const takeProfitStopLoss = {
  SLTP: [-1, 0, 1, 2],
  SLTPValues: ["10% / -7%", "15% / -10%", "20% / -15%", "30% / -20%"]
};

export const riskGrades = {
  Low: "A - Low",
  Medium: "B - Medium",
  High: "C - High",
  Rejected: "Rejected"
};

export const finamazeBankAccount = {
  NewYork: "New York Bank",
  AbuDhabi: "Abu Dhabi Bank"
};

export const creditReasons = {
  Rebate: "REBATE",
  Deposit: "DEPOSIT"
};

export const debitReasons = {
  Rebate: "REBATE",
  Deposit: "DEPOSIT"
};

export const DocumentsRequiredOptions = [
  {
    name: "onboardingDocumentsRequired.WealthDocs",
    id: "WealthDocs",
    label: "Wealth"
  },
  {
    name: "onboardingDocumentsRequired.Passport",
    id: "Passport",
    label: "Passport and Facial"
  },
  {
    name: "onboardingDocumentsRequired.FacialReco",
    id: "FacialReco",
    label: "Only Facial"
  },
  {
    name: "onboardingDocumentsRequired.ProofAddress",
    id: "ProofAddress",
    label: "Proof of Address"
  },
  {
    name: "onboardingDocumentsRequired.ExperienceDocs",
    id: "ExperienceDocs",
    label: "Experience"
  },
  {
    name: "onboardingDocumentsRequired.riskAssessmentIncomplete",
    id: "riskAssessmentIncomplete",
    label: "Risk Assessment Incomplete"
  }
];
