import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState: {toasts: Array<any>} = {
    toasts: []
}

export const toasterSlice = createSlice({
    name: "toaster",
    initialState,
    reducers: {
        addToast: (state,action) => {
            state.toasts.push({...action.payload, id: nanoid()})
        },
        removeToast: (state,action) => {
            state.toasts = state.toasts.filter(toast => toast.id !== action.payload)
        }
    }
})
export const {addToast, removeToast} = toasterSlice.actions