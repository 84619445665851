import { createSlice } from "@reduxjs/toolkit";
import { initialAuthState } from "./types";

const initialState: initialAuthState = {
  authenticatedUser: undefined
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLogout: (state, payload) => {
      localStorage.removeItem("token");
    },
    getAuthenticatedUser: (state, action) => {
      state.authenticatedUser = action.payload;
    }
  }
});
export const { userLogout, getAuthenticatedUser } = authSlice.actions;
