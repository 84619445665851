import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";

export const strategySettingServiceApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getStrategies: builder.query<any, void>({
      query: () => ({
        url: urlPrepare(process.env.REACT_APP_GET_STRATEGIES),
        headers: {
          "x-fields":
            "_id,smartfolio_name,asset_class,market,portal_name,name,description,solution_body,basket_body,notional,creation_date,frequency",
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }),
      providesTags: ["StrategieSettingService"]
    }),
    getStrategyPerformance: builder.query<any, string>({
      query: (strategyId) =>
        urlPrepare(`/admin/navs/history?smartfolioID=${strategyId}&clientType=`),
      providesTags: ["StrategieSettingService"]
    }),
    getStrategyGroups: builder.query<any, void>({
      query: () => urlPrepare("/pam/strategy-group/"),
      providesTags: ["StrategieSettingService"]
    }),
    getStrategyGroupsById: builder.query<any, string>({
      query: (strategyGroupsId) => urlPrepare(`/pam/strategy-group/${strategyGroupsId}`),
      providesTags: ["StrategieSettingService"]
    }),
    getStrategyGroupsByManagerId: builder.query<any, string>({
      query: (strategyManagerId) =>
        urlPrepare(`/pam/strategy-group-by-manager/?managerId=${strategyManagerId}`),
      providesTags: ["StrategieSettingService"]
    }),
    getStrategyById: builder.query<any, string>({
      query: (strategie_id) =>
        urlPrepare(process.env.REACT_APP_GET_STRATEGIE_BY_ID + { strategie_id }.strategie_id),
      providesTags: ["StrategieSettingService"]
    }),
    newStrategySetting: builder.mutation<any, any>({
      query: (newStrategy) => ({
        url: urlPrepare(process.env.REACT_APP_ADD_NEW_STRATEGIE),
        method: "POST",
        body: newStrategy
      }),
      invalidatesTags: ["StrategieSettingService"]
    }),
    editStrategy: builder.mutation<any, any>({
      query: ({ editedStrategie, id }) => ({
        url: urlPrepare(process.env.REACT_APP_ADD_NEW_STRATEGIE + id),
        method: "PUT",
        body: editedStrategie
      }),
      invalidatesTags: ["StrategieSettingService"]
    }),
    deleteStrategy: builder.mutation<any, string>({
      query: (id) => ({
        url: urlPrepare(`${process.env.REACT_APP_DELETE_STRATEGIE_BY_ID}${id}`),
        method: "DELETE"
      }),
      invalidatesTags: ["StrategieSettingService"]
    }),
    deleteMultipleStrategies: builder.mutation<any, any>({
      query: (items) => ({
        url: urlPrepare(`/settings/strategies?items=${items}`),
        method: "DELETE"
      }),
      invalidatesTags: ["StrategieSettingService"]
    })
  })
});

export const {
  useGetStrategiesQuery,
  useGetStrategyPerformanceQuery,
  useGetStrategyGroupsQuery,
  useGetStrategyGroupsByIdQuery,
  useGetStrategyGroupsByManagerIdQuery,
  useGetStrategyByIdQuery,
  useNewStrategySettingMutation,
  useEditStrategyMutation,
  useDeleteStrategyMutation,
  useDeleteMultipleStrategiesMutation
} = strategySettingServiceApi;
