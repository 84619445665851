import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";

export const dividendApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDividend: builder.query<any, { page: number }>({
      query: (params) =>
        urlPrepare(process.env.REACT_APP_GET_DIVIDEND, null, { ...params, limit: 10 }),
      providesTags: ["Dividend"]
    }),
    getDividendUnits: builder.query<any, void>({
      query: () => urlPrepare(process.env.REACT_APP_GET_DIVIDEND_UNITS),
      providesTags: ["Dividend"]
    }),
    createDividend: builder.mutation<any, any>({
      query: (newDividend) => ({
        url: urlPrepare(process.env.REACT_APP_DIVIDEND_CREATE),
        method: "POST",
        body: newDividend
      }),
      invalidatesTags: ["Dividend"]
    }),
    confirmDividend: builder.mutation<any, any>({
      query: ({ id, dividend }) => ({
        url: urlPrepare(process.env.REACT_APP_DIVIDEND_CONFIRM, { dividendId: id }),
        method: "PUT",
        body: { comment: dividend }
      }),
      invalidatesTags: ["Dividend"]
    }),
    rejectDividend: builder.mutation<any, any>({
      query: ({ id, dividend }) => ({
        url: urlPrepare(process.env.REACT_APP_DIVIDEND_REJECT, { dividendId: id }),
        method: "PUT",
        body: { comment: dividend }
      }),
      invalidatesTags: ["Dividend"]
    })
  })
});

export const {
  useGetDividendQuery,
  useGetDividendUnitsQuery,
  useCreateDividendMutation,
  useConfirmDividendMutation,
  useRejectDividendMutation
} = dividendApi;
