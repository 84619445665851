import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";

export const tickersApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getTickers: builder.query<any, void>({
      query: () => urlPrepare("/tickers/light"),
      providesTags: ["Tickers"]
    }),
    addFavorite: builder.mutation<any, string>({
      query: (symbol) => ({
        url: urlPrepare("/addFavouriteTicker"),
        method: "POST",
        body: { symbol }
      }),
      invalidatesTags: ["Tickers"]
    }),
    getFavoriteTickers: builder.query<any, void>({
      query: () => ({
        url: urlPrepare("/getFavoriteTickers")
      }),
      providesTags: ["Tickers"]
    }),
    deleteFavorite: builder.mutation<any, string>({
      query: (symbol) => ({
        url: urlPrepare("/deleteFavouriteTicker"),
        method: "POST",
        body: { symbol }
      }),
      invalidatesTags: ["Tickers"]
    }),
    getTickersByFields: builder.query<any, any>({
      query: (fields) => `/sentiments/tickers?fields=${fields}`,
      providesTags: ["Tickers"]
    }),
    getTickersLivePrices: builder.query<any, void>({
      query: () => "/sentiments/tickers?fields=details,equidistant_nav,zag_price",
      providesTags: ["Tickers"]
    })
  })
});

export const {
  useGetTickersQuery,
  useAddFavoriteMutation,
  useGetFavoriteTickersQuery,
  useDeleteFavoriteMutation,
  useGetTickersByFieldsQuery,
  useGetTickersLivePricesQuery
} = tickersApi;
