import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  skip: true
};

export const StrategieSlice = createSlice({
  name: "dividend",
  initialState,
  reducers: {
    setSkip: (state, action) => {
      state.skip = action.payload;
    }
  }
});

export const { setSkip } = StrategieSlice.actions;
