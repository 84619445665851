import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";
import { Statement } from "./types";

export const accountStatementsApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllStatements: builder.query<Statement[], string | undefined>({
      query: (owner) =>
        owner
          ? urlPrepare(process.env.REACT_APP_CLIENT_STATEMENTS_ROUTE, { owner })
          : urlPrepare(process.env.REACT_APP_STATEMENTS_ROUTE)
    })
  })
});
export const { useGetAllStatementsQuery } = accountStatementsApi;
