import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";
import { Basket } from "./types";

export const basketSettingServiceApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getBaskets: builder.query<Basket[], void>({
      query: () => urlPrepare(process.env.REACT_APP_GET_BASKETS),
      providesTags: ["BasketSettingService"]
    }),
    getBasketById: builder.query<Basket, string>({
      query: (basket_id) => process.env.REACT_APP_GET_BASKET_BY_ID + basket_id,
      providesTags: ["BasketSettingService"]
    }),
    newBasket: builder.mutation<Basket, Basket>({
      query: (newBasket) => ({
        url: urlPrepare(process.env.REACT_APP_ADD_NEW_BASKET),
        method: "POST",
        body: newBasket
      }),
      invalidatesTags: ["BasketSettingService"]
    }),
    editBasket: builder.mutation<Basket, { editedBasket: Basket; id: string }>({
      query: ({ editedBasket, id }) => ({
        url: urlPrepare(process.env.REACT_APP_ADD_NEW_BASKET, { id }),
        method: "POST",
        body: editedBasket
      }),
      invalidatesTags: ["BasketSettingService"]
    }),
    deleteBasket: builder.mutation<Basket, string>({
      query: (id) => ({
        url: urlPrepare(process.env.REACT_APP_DELETE_BASKETS_BY_ID, { id }),
        method: "DELETE"
      }),
      invalidatesTags: ["BasketSettingService"]
    }),
    deleteBaskets: builder.mutation<Basket[], Basket[]>({
      query: (items) => ({
        url: `/settings/baskets?items=${items}`,
        method: "DELETE"
      }),
      invalidatesTags: ["BasketSettingService"]
    })
  })
});

export const {
  useGetBasketsQuery,
  useGetBasketByIdQuery,
  useNewBasketMutation,
  useEditBasketMutation,
  useDeleteBasketMutation,
  useDeleteBasketsMutation
} = basketSettingServiceApi;
