import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";
import { Sentiment } from "./types";

export const sentimentsApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getSentiment: builder.query<Sentiment[], void>({
      query: () => ({
        url: urlPrepare(process.env.REACT_APP_API_HOST + "sentiments/tickers?fields=sentiment")
      })
    })
  })
});

export const { useGetSentimentQuery } = sentimentsApi;
