import { urlPrepare } from "helpers";
import { Template } from "./types";
import { SplitApi } from "redux/api";
import { toast } from "react-toastify";

export const templatesApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTemplates: builder.query<Template[], void>({
      query: () => urlPrepare(process.env.REACT_APP_TEMPLATES),
      providesTags: ["Templates"]
    }),
    getTemplate: builder.query<Template, string>({
      query: (templateName) =>
        urlPrepare(process.env.REACT_APP_TEMPLATE_NAME, {
          name: templateName
        }),
      providesTags: ["Templates"]
    }),
    createTemplate: builder.mutation<any, any>({
      query: ({ template, type }) => ({
        url: urlPrepare(process.env.REACT_APP_TEMPLATES),
        method: "POST",
        body: { ...template, type }
      }),
      transformResponse: () => toast.success("Template created successfully"),
      transformErrorResponse: () => toast.error("Template creation failed"),
      invalidatesTags: ["Templates"]
    }),
    editTemplate: builder.mutation<any, any>({
      query: ({ template, id }) => {
        return {
          url: urlPrepare(process.env.REACT_APP_TEMPLATE_ID, {
            id: id
          }),
          method: "PUT",
          body: template
        };
      },
      transformResponse: () => toast.success("Template updated successfully"),
      transformErrorResponse: () => toast.error("Template update failed"),
      invalidatesTags: ["Templates"]
    }),
    deleteTemplate: builder.mutation<any, string>({
      query: (templateName) => ({
        url: urlPrepare(process.env.REACT_APP_TEMPLATE_NAME, { name: templateName }),
        method: "DELETE"
      }),
      transformResponse: () => toast.success("Template deleted successfully"),
      transformErrorResponse: () => toast.error("Template deletion failed"),
      invalidatesTags: ["Templates"]
    })
  })
});

export const {
  useGetAllTemplatesQuery,
  useGetTemplateQuery,
  useCreateTemplateMutation,
  useEditTemplateMutation,
  useDeleteTemplateMutation
} = templatesApi;
