import React from "react";
import Swal from "sweetalert2";
import { getModeColor } from "utils/theme";

const SwalWithDarkMode = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-info",
    cancelButton: "btn btn-danger"
  },
  buttonsStyling: false,
  didOpen: () => {
    SwalWithDarkMode.update({ background: getModeColor() === "realDark" ? "#181818" : "white" });
  }
});

export default SwalWithDarkMode;
